import * as React from "react";
import Helmet from "react-helmet";

import { Error } from "components/404/404";

export default () => (
  <>
    <Helmet title="404 Not Found" />

    <Error />
  </>
);
